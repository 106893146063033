/* ============================================================================
   HEADER -> MEDIA SCREEN
   ========================================================================= */

/**
 * 1. Header Style.
 * 2. Media Screen Style.
 */

/* 1. Header Style.
   ========================================================================= */

.header {
    background-color: $gray-600;
    padding: 0 0.25rem;

    &__logo {
      min-width: 15.25rem;
      position: relative;
   }

   &__version {
      color: $white;
      font-size: 0.5625rem;
      position: absolute;
      right: 0;
      top: 100%;
      right: 0.1875rem;
      opacity: 0.8;
   }

   &__search {
      padding: 0 1rem 0 2rem;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      width: 100%;
      position: relative;

      &-icon {
         color: $white;
         left: 3rem;
         position: absolute;
         top: 0.625rem;
         z-index: 1;
         opacity: 0.75;
      }

      &-input {
         background: transparent;
         border-color: rgba(255, 255, 255, .5);
         color: $white;
         padding-left: 2.5rem;
         border-radius: 50rem;

         &:focus {
            background-color: transparent;
            border-color: rgba(255, 255, 255, 0.75);
            color: $white;
            outline: 0;
          }
      }
    }
}

.search-results {
   background-color: $white;
   display: block;
   position: absolute;
   left: 2.5rem;
   top: 100%;
   width: calc(100% - 5rem);
   z-index: 9;
   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
   overflow-y: auto;
   max-height: 400px;

   &__item {
      border-bottom: 0.0625rem solid $gray-200;
      cursor: pointer;
      display: flex;
      padding: 0.5rem 1rem;
      transition: $transition-base;

      &:hover {
         background-color: rgba(0, 0, 0, 0.025);
      }
   }
}

.sub-header {
   background-color: $white;
   border: 1px solid $gray-200;
   padding: 0 0.5rem;

   .border-start {
      border-left: 1px solid $gray-200 !important;
   }

   .border-end {
      border-right: 1px solid $gray-200 !important;
   }
}

/* 2. Media Screen Style.
   ========================================================================= */

/*media screen for width 991px and down*/
@include media-breakpoint-down(lg) {
  
}

/*media screen for width 767px and down*/
@include media-breakpoint-down(md) {
   .header {
     &__search {
         margin: 0.5rem 0 0 0;
         padding: 0;

         &-icon {
            left: 1rem;
         }

         &-input {
            border-radius: 0.25rem;
         }
     }
   }
}

/*media screen for width 575px and down*/
@include media-breakpoint-down(sm) {
   .header {
      &__logo {
        min-width: auto;
        max-width: 13rem;
     }
   }
}