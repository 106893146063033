// Row
//
// Rows contain your columns.

@if $enable-grid-classes {
  .row {
    @include make-row();

    > * {
      @include make-col-ready();
    }
  }
}
.mbsc-flex-1-1 .mbsc-flex-col.mbsc-ios .mbsc-timeline-resource-bg > div:nth-child(even) {
  background-color: #f3f7ff !important;
}

.mbsc-ios.mbsc-schedule-all-day-item:after, .mbsc-ios.mbsc-schedule-column, .mbsc-ios.mbsc-schedule-item, .mbsc-ios.mbsc-schedule-resource, .mbsc-ios.mbsc-schedule-resource-group, .mbsc-ios.mbsc-timeline-column, .mbsc-ios.mbsc-timeline-day:after, .mbsc-ios.mbsc-timeline-footer, .mbsc-ios.mbsc-timeline-footer-column, .mbsc-ios.mbsc-timeline-footer-date, .mbsc-ios.mbsc-timeline-footer-week, .mbsc-ios.mbsc-timeline-header, .mbsc-ios.mbsc-timeline-header-column, .mbsc-ios.mbsc-timeline-header-date, .mbsc-ios.mbsc-timeline-header-month, .mbsc-ios.mbsc-timeline-header-week, .mbsc-ios.mbsc-timeline-resource, .mbsc-ios.mbsc-timeline-resource-footer-cont, .mbsc-ios.mbsc-timeline-resource-header-cont, .mbsc-ios.mbsc-timeline-row, .mbsc-ios.mbsc-timeline-row-date, .mbsc-ios.mbsc-timeline-row-group:after, .mbsc-ios.mbsc-timeline-sidebar-footer-cont, .mbsc-ios.mbsc-timeline-sidebar-header-cont, .mbsc-ios.mbsc-timeline-sidebar-resource, .mbsc-ios.mbsc-timeline-slot-header, .mbsc-ios.mbsc-timeline-slots{
  border-color: #1e1d1d !important;
}
.mbsc-ios.mbsc-calendar-header, .mbsc-ios.mbsc-calendar-wrapper{
  border-color: #303030 !important;
}
// Columns
//
// Common styles for small and large grid columns

@if $enable-grid-classes {
  @include make-grid-columns();
}
