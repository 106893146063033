@keyframes animate-top {
    0% {
        opacity: 0;
        transform: translateY(0.5rem);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

//
// background
//

.bg-offwhite {
    background: $body-bg-offwhite !important;
}

.cursor-pointer {
    cursor: pointer;
}

//
// Font Sizes
//

.fs-10 {
    font-size: 0.6125rem !important;
}

.fs-11 {
    font-size: 0.6875rem !important;
}

.fs-12 {
    font-size: 0.75rem !important;
}

.fs-13 {
    font-size: 0.8125rem !important;
}

.fs-14 {
    font-size: 0.875rem !important;
}

.fs-15 {
    font-size: 0.9375rem !important;
}

.fs-16 {
    font-size: 1rem !important;
}

.fs-17 {
    font-size: 1.0625rem !important;
}

.fs-18 {
    font-size: 1.125rem !important;
}

.fs-20 {
    font-size: 1.25rem !important;
}
.fs-22 {
    font-size: 1.375rem !important;
}
.fs-24 {
    font-size: 1.5rem !important;
}

.fs-32 {
    font-size: 2rem !important;
}

.fs-64 {
    font-size: 4rem !important;
}

.p-05 {
    padding: 0.125rem;
}

.pt-05 {
    padding-top: 0.125rem;
}

.py-06125 {
    padding: 0.6125rem 0.75rem !important;
}

//
// Width
//

.w-4-5 {
    width: 4.5rem !important;
}
.mw-35 {
    min-width: 3.5rem;
}
.mw-4 {
    min-width: 4rem;
}
.mw-5 {
    min-width: 5rem;
}
.mw-75 {
    min-width: 75rem;
}
.mw-8 {
    min-width: 8rem;
}
.mw-10 {
    min-width: 10rem;
}
.mw-13 {
    max-width: 13rem;
}
.mt-05 {
    margin-top: 0.125rem !important;
}
.mt--05 {
    margin-top: -0.125rem !important;
}
.mt--1 {
    margin-top: -0.25rem !important;
}
.mt--375 {
    margin-top: -0.375rem !important;
}
.mt--2 {
    margin-top: -0.5rem !important;
}
.mt--3 {
    margin-top: -1rem !important;
}
.mt-125 {
    margin-top: 0.125rem !important;
}
.mt-1r {
    margin-top: 1rem !important;
}
.mr-1r {
    margin-right: 1rem !important;
}
.opacity-5 {
    opacity: 0.5;
}

.text-suffix {
    position: relative;

    &:after {
        content: ':';
        position: absolute;
        font-weight: 400;
        right: 0.5rem;
        top: 0;
    }
}

.btn-dark,
.btn-outline-dark {
    &:hover,
    &:focus {
        background: $blue !important;
        border-color: $blue !important;
        color: $white !important;
    }
}

a.text-dark {
    transition: $transition-base;

    &:hover,
    &:focus {
        color: $blue !important;
    }
}

//
// Custom Table
//

.tb {
    background: $white;
    border-radius: 0.25rem;

    &__head {
        border: 1px solid $gray-200 !important;
        font-weight: 700;
        margin-top: -0.0625rem;
        padding: 1rem;
    }

    &__row {
        border: 1px solid $gray-200 !important;
        margin-top: -0.0625rem;
        padding: 1rem;
        transition: $transition-base;

        &:hover {
            background: $gray-100;
        }
    }
}

/* Filter Style.
  ========================================================================= */

.filter {
    display: block;
    margin: 0;
    padding: 0;

    &__item {
        display: inline-block;
    }

    &__link {
        background: $white;
        border: 0.0625rem solid $gray-border;
        border-radius: 1.25rem;
        color: $gray-500;
        display: inline-block;
        margin: 0 0.5rem 0 0;
        padding: 0.25rem 1rem;
        text-decoration: none;

        &.is-active,
        &:hover,
        &:focus {
            background: $secondary;
            border: 0.0625rem solid $secondary;
            color: $white;
        }
    }
}

/* Tab Style.
  ========================================================================= */

.tab {
    display: block;
    margin: 0;
    padding: 0;

    &__item {
        display: inline-block;
    }

    &__link {
        color: $gray-500;
        display: inline-block;
        margin-right: 1.5rem;
        padding: 0.75rem 0;
        position: relative;
        text-decoration: none;

        &:before {
            content: '';
            background: $primary;
            height: 0.1875rem;
            bottom: 0;
            font-weight: 700;
            left: 50%;
            right: 50%;
            position: absolute;
            transition: $transition-base;
        }

        &.is-active,
        &:hover,
        &:focus {
            color: $gray-500;

            &:before {
                left: 0;
                right: 0;
            }
        }
    }
}

/* Avatar Style.
  ========================================================================= */

.avatar {
    background: $greenDark;
    border-radius: 50%;
    margin: 0 auto;
    height: 7.5rem;
    line-height: 7.5rem;
    font-size: 3rem;
    overflow: hidden;
    width: 7.5rem;
}

/* Avatar Style.
  ========================================================================= */

.somatus-logo {
    fill: $greenDark;
    height: 2.5rem;
    width: 2.5rem;
    vertical-align: middle;
}

/* PDF Style.
    ========================================================================= */

.pdf {
    display: block;
    margin: 0;
    padding: 0;
    height: 1190px;
}

/* Error Page Style.
   ========================================================================= */

.error-page,
.thanks-page {
    &__svg {
        display: block;
        fill: $primary;
        margin: 0 auto;
        height: 10rem;
        width: 10rem;
        vertical-align: middle;
    }
}

/* Assessment Page Style.
   ========================================================================= */

.assessment {
    display: block;
    margin-bottom: 1rem;

    &__item {
        border-radius: 0.25rem;
        cursor: pointer;
        display: block;
        border: 1px solid $gray-border;
        padding: 0.5rem 1rem;
    }

    .form-check-input {
        position: absolute;
        z-index: -1;
        opacity: 0;

        &:checked {
            + .assessment__item {
                border: 1px solid $primary;
                background: $primary;
            }
        }
    }
}

.tabs {
    border-bottom: 0.0625rem solid $gray-border;
    display: block;
    margin: 0 0 1rem 0;
    padding: 0;

    &__list {
        display: inline;
    }

    &__item {
        background: none;
        border: none;
        color: $gray-300;
        display: inline-block;
        font-weight: 700;
        margin-right: 2rem;
        padding: 0.375rem 0;
        position: relative;
        transition: $transition-base;

        &:before {
            background-color: $blue;
            bottom: -0.0625rem;
            content: '';
            display: block;
            left: 50%;
            right: 50%;
            height: 0.125rem;
            position: absolute;
            transition: $transition-base;
        }

        &.active {
            color: $blue;

            &:before {
                left: 0;
                right: 0;
            }
        }
    }
}

.iframe {
    min-height: 20rem;
    width: 100%;
}

.iframe-lg {
    border: 0.1875rem dashed $gray-border;
    border-radius: 0.25rem;
    min-height: 20rem;
    padding: 0.25rem;
    width: 100%;
}

.user {
    border: 0.0625rem solid $blue;
    border-radius: 50%;
    color: $blue;
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: 1rem;
    font-weight: 700;
    width: 2.5rem;
    text-align: center;
}

.box {
    padding: 0 1rem;
    position: relative;
    overflow: hidden;
}

.btn-icon {
    opacity: 0.25;
    transition: $transition-base;

    &:hover,
    &:focus {
        opacity: 1;
    }
}

.schedule-calendar,
.my-calendar-view {
    border: 0.1875rem dashed $gray-border;
    border-radius: 0.25rem;

    .mbsc-schedule-header {
        border-top: 0.0625rem solid $gray-border;
        margin-top: 0.25rem;
    }

    .mbsc-font {
        font-family: $font-family-sans-serif;
    }

    .mbsc-calendar-header {
        background: $bluelight;
    }

    .mbsc-ios.mbsc-button-flat {
        color: $blue !important;
    }
    .mbsc-timeline-resource {
        display: block;
        align-items: center;
    }
    .mbsc-timeline-resource-title {
        font-size: 0.8125em;
        line-height: 1.2;
    }
    #schedule-timeline {
        .mbsc-timeline-resource {
            height: auto;
        }
    }
    .mbsc-icon-button {
        padding: 0 !important;
    }

    .mbsc-icon-button {
        height: auto;
        width: auto;
    }
}
.input-datepicker {
    input {
        background: none;
        border: none;
        padding: 0 0.5rem;
        font-size: 1.5em;
        color: $blue;
        outline: none;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }
}
#filterOption {
    .date-range {
        label {
            display :none;
        }
    }
}
.appointment-start {
    .date-range {
        label {
            display :none;
        }
        &:before {
            left: 1px;
            width: 37px;
        }
        &::after {
            left: 0.875rem;
            top: 0.5rem;
        }
        margin: 0rem;
        .mbsc-ios.mbsc-textfield {
            height: 2.0625rem;
            font-size: 0.875rem;
            border: 1px solid #cccfd3;
            padding-left: 3rem;
        }
    }
}

.date-range {
    margin: 0 1rem;
    position: relative;

    &:before {
        content: '';
        background-color: #ebecf2;
        border-right: 1px solid #cccfd3;
        position: absolute;
        left: 2px;
        top: 1px;
        bottom: 1px;
        width: 40px;
        z-index: 1;
    }

    &:after {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: '\f073';
        position: absolute;
        color: $blue;
        left: 1rem;
        top: 0.6125rem;
        z-index: 1;
    }

    .mbsc-block,
    .mbsc-form-group,
    .mbsc-ios.mbsc-textfield-wrapper-box {
        margin: 0;
    }
    .mbsc-ios.mbsc-textfield {
        height: 2.375rem;
        font-size: 0.875rem;
    }
    .mbsc-ios.mbsc-textfield-box {
        border: 1px solid $gray-border;
        border-radius: 0.125rem;
        min-width: 14.5rem;
        padding-left: 3.5rem !important;

        &:focus {
            color: $input-focus-color;
            background-color: $input-focus-bg;
            border-color: $input-focus-border-color;
            outline: 0;
            @include box-shadow($input-box-shadow, $input-focus-box-shadow);
            box-shadow: $input-focus-box-shadow;
        }
    }
}

/* Login Style.
    ========================================================================= */

.authincation-content {
    background: $white;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}

.login__wrap {
    background: $white;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
}
.login {
    text-align: center;
}

.contact-number {
    border: 1px solid $gray-border;
    background-color: $gray-200;
    border-radius: 50rem;
    color: $dark;
    line-height: normal;
    margin-top: -0.25rem;
    padding: 0.25rem 1.5rem 0.25rem 3rem;
    position: relative;
    transition: $transition-base;

    &__icon {
        color: $dark;
        font-size: 1.5rem;
        opacity: 0.75;
        left: 1rem;
        position: absolute;
        top: 0.625rem;
    }

    &__info {
        display: block;
        color: $dark;
        font-size: 0.75rem;
        opacity: 0.75;
    }

    &__hover {
        border: 1px solid $primary;
        cursor: pointer;
        background-color: $dark;
        color: $white;

        .contact-number__icon {
            color: $white;
        }

        .contact-number__info {
            color: $white;
        }

        &:hover,
        &:focus {
            background-color: $primary;
        }
    }
}

.app-version {
    color: $gray-600;
    font-size: 0.5625rem;

    left: 1rem;
    right: 1rem;
    bottom: 0;
    text-align: right;
}

.modal .results-panel {
    overflow-x: hidden;
    overflow-y: auto;
    height: 250px;
}

.modal .care-member-result {
    background-color: #fff;
    display: block;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    min-width: 16.75rem;
    z-index: 1;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: 400px;

    &__item {
        border-bottom: 0.0625rem solid #E6EAED;
        cursor: pointer;
        display: block;
        padding: 0.5rem 1rem;
        transition: all 0.2s linear;

        &:hover {
            background-color: rgba(0, 0, 0, 0.025);
        }
    }
}

.modal .care-member-slot {
    display: block;
    border-radius: 0.125rem;
    color: $white;
    font-size: 0.75rem;
    text-align: center;
    margin-top: 0.3125rem;
    line-height: 1;
    white-space: nowrap;
    opacity: 0.3;
    padding: 0.25rem;
    position: relative;

    &.is-active {
        opacity: 1;
    }
    .is-time {
        font-size: 0.5625rem;
        position: relative;
        margin-top: 0.1875rem;
    }
}

.modal .care-member-result-selected {
    background-color: aliceblue;
}

.btn-delete,
.btn-menu {
    color: $gray-300;
    position: absolute;
    right: 0.75rem;
    top: -1rem;
    cursor: pointer;
    transition: $transition-base;

    &:hover {
        color: $blue;
    }
}

.btn-menu {
    right: 0;
}

.aside-model {
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
    width: 100%;
    max-width: 35rem;
    background-color: $white;
    position: fixed;
    top: 0;
    right: -35rem;
    bottom: 0;
    z-index: 1061;
    transition: $transition-base;

    &__overlay {
        background-color: $black;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1060;
        display: none;
        width: 100%;
        height: 100%;
        overflow: hidden;
        outline: 0;
        opacity: 0.5;
        transition: $transition-base;
    }
    &__header {
        padding: 1rem 3rem 1rem 1.25rem;
        background-color: $gray-150;
        border-bottom: 0.125rem dashed $gray-border;
        position: relative;
    }
    &__close {
        position: absolute;
        top: 1rem;
        right: 1.25rem;
        font-size: 1rem;
        opacity: 0.5;
        transition: $transition-base;
        cursor: pointer;
        &:hover {
            opacity: 1;
            transform: rotate(90deg);
        }
    }
    &__content {
        overflow-y: auto;
        padding: 1rem 1.25rem;
        height: calc(100vh - 3.25rem);
    }
}

.is-asidemodel {
    overflow: hidden;
    .aside-model {
        right: 0;
        &__overlay {
            display: block;
        }
    }
}

/* Media Screen Style.
    ========================================================================= */

/*media screen for width 768px and up*/
@include media-breakpoint-up(md) {
    .mt--md-2 {
        margin-top: -0.5rem !important;
    }

    .mt--375-md {
        margin-top: -0.375rem !important;
    }

    .text-truncate-md {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .text-nowrap-md {
        white-space: nowrap !important;
    }
}
/*media screen for width 992px and up*/
@include media-breakpoint-up(lg) {
    .border-start-md {
        border-left: 1px solid #e6eaed !important;
    }
    .border-end-md {
        border-right: 1px solid #e6eaed !important;
    }
    .map-div {
        min-height: calc(100vh - 230px);
        display: flex;
        flex-direction: column;
    }
}

/*media screen for width 1199px and down*/
@include media-breakpoint-down(xl) {
    .tb {
        font-size: 0.875rem;
    }
    .somatus-logo {
        height: 2.375rem;
        width: 2.375rem;
    }
}

/*media screen for width 991px and down*/
@include media-breakpoint-down(lg) {
    .filter {
        &__link {
            padding: 0.125rem 1rem;
            margin: 0.25rem 0.5rem 0.25rem 0;
        }
    }
    .somatus-logo {
        height: 2.25rem;
        width: 2.25rem;
    }

    .iframe,
    .iframe-lg {
        min-height: 20rem !important;
    }

    .contact-number {
        margin-top: -1.75rem;
    }

    .modal .care-member-slot {
        margin-top: 0.25rem;
    }
}

/*media screen for width 767px and down*/
@include media-breakpoint-down(md) {
    .date-range {
        margin: 0.5rem 0;
    }

    .avatar {
        height: 5rem;
        line-height: 5rem;
        font-size: 2rem;
        width: 5rem;
    }

    .somatus-logo {
        height: 2.125rem;
        width: 2.125rem;
    }

    .error-page,
    .thanks-page {
        &__svg {
            height: 7.5rem;
            width: 7.5rem;
        }
    }

    .contact-number {
        margin-top: 0;
    }

    .schedule-calendar,
    .my-calendar-view {
        border: 0.1875rem dashed $gray-border;
        border-radius: 0.25rem;

        .mbsc-calendar-controls {
            display: block;
            text-align: center;
        }
        .mbsc-schedule-event {
            width: auto !important;
            white-space: nowrap;
        }
    }
}

/*media screen for width 575px and down*/
@include media-breakpoint-down(sm) {
    .somatus-logo {
        height: 2rem;
        width: 2rem;
    }

    .login {
        left: 1rem;
        right: 1rem;
        margin: -7.3125rem 0 0 0;
        width: auto;
    }
}
.display-none {
    display: none;
}
.event-border {
    .mbsc-timeline-event-background {
        border : 1px solid #000;
    }
}