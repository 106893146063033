/* ============================================================================
   FOOTER -> MEDIA SCREEN
   ========================================================================= */

/**
 * 1. Footer Style.
 * 2. Media Screen Style.
 */

/* 1. Footer Style.
   ========================================================================= */

.footer {
    background: none;
    color: $white;
    padding: 0rem 0;
    text-align: right;
    
    &__list {
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: right;
    }

    &__item {
        display: inline;
    }

    &__link {
        color: $white;
        display: inline-block;
        margin: 0 0 0 1.5rem;
        text-decoration: none;
    }
    
    &__collapse {

        .fa-chevron-up {
            font-size: 0.75rem;
        }

        &.collapsed {
            .fa-chevron-up {
                -webkit-transform: scaleY(-1);
                transform: scaleY(-1);
            }
        }
    }
}

.chatbot {
    position: relative;

    &__head {
        background-color: $blue;
        border-radius: 0.25rem 0.25rem 0 0;
        color: $white;
        font-size: 1.25rem;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        padding: 0.5rem 1rem;
    }

    &__footer {
        background-color: $white;
        border-radius: 0 0 0.25rem 0.25rem;
        border-top: 1px solid $gray-border;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0.5rem 3rem 0.5rem 1rem;
    }

    &__input {
        border: none;
        font-size: 0.875rem;
        padding: 0.5rem 1rem 0.5rem 0;
        width: 100%;

        &:focus {
            outline: 0;
        }
        &::placeholder {
            color: $gray-border;
        }
    }

    &__btn {
        position: absolute;
        border: none;
        background: none;
        right: 1rem;
        top: 0.75rem;
        padding: 0;
        margin: 0;
        opacity: 0.75;
        transition: $transition-base;
        
        &-icon {
            fill: $blue;
            height: 1.25rem;
            width: 1.25rem;
        }

        &:hover,
        &:focus {
            opacity: 1;
        }
    }

    &__block {
        background-color: $gray-100;
        border: 1px solid $gray-border;
        border-radius: 0.25rem;
        display: none;
        opacity: 0;
        height: 22rem;
        width: 18rem;
        position: fixed;
        right: 1.5rem;
        bottom: 6rem;
        box-shadow: $box-shadow;
        z-index: 2;
        transition: $transition-base;
        animation: animate-top .2s ease-in-out;
    }

    &__content {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 3.5rem;
        top: 3rem;
        overflow-x: hidden;
        overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 0.5rem;
            }
            &::-webkit-scrollbar-track {
                background: rgba(0,0,0,0.1);
            }
            &::-webkit-scrollbar-thumb {
                background-color: $blue;
                border-radius: 0.25rem;
                cursor: pointer;
                opacity: 0.5;
                transition: $transition-base;

            &:hover {
                opacity: 1;
            }
        }

    }

    &__message {
        background-color: $gray-200;
        border-radius: 0.25rem;
        color: $blue;
        display: inline-block;
        font-size: 0.75rem;
        line-height: 0.75rem;
        padding: 0.5rem 1rem;
        margin-left: 2rem;
    }

    &__reply {
        background-color: $white;
        border: 1px solid $gray-200;
        border-radius: 0.25rem;
        box-shadow: $box-shadow-angular;
        color: $gray-500;
        display: inline-block;
        font-size: 0.75rem;
        line-height: 0.75rem;
        margin-left: 2rem;
        padding: 0.5rem 1rem;
        position: relative;
    }
    &__time {
        display: block;
        margin: 0.5rem 1rem;
        position: relative;
        text-align: center;

        &:before {
            content: '';
            height: 1px;
            display: block;
            border-top: 1px dashed $gray-200;
            position: absolute;
            top: 50%;
            width: 100%;
        }

        span {
            background-color: $gray-100;
            font-size: 0.75rem;
            display: inline-block;
            padding: 0.5rem;
            position: relative;
            z-index: 1;
        }
    }

    &__user {
        background-color: $white;
        border: 1px solid $gray-200;
        border-radius: 50%;
        color: $blue;
        display: block;
        font-size: 0.875rem;
        height: 1.75rem;
        line-height: 1.5rem;
        left: 0;
        text-align: center;
        position: absolute;
        top: 0.375rem;
        width: 1.75rem;
        position: absolute;
        left: -2rem;
        top: 0;
    }

    &__icon {
        background-color: $green;
        border-radius: 50%;
        cursor: pointer;
        height: 4rem;
        width: 4rem;
        position: fixed;
        padding: 1rem;
        right: 1.5rem;
        bottom: 1.5rem;
        text-align: center;
        box-shadow: $box-shadow-angular;
        z-index: 1;

        &-info {
            background-color: $orange;
            border-radius: 50%;
            cursor: pointer;
            color: $white;
            font-size: 0.675rem;
            height: 1.25rem;
            width: 1.25rem;
            position: absolute;
            padding: 0.125rem;
            right: 0.675rem;
            top: 0.675rem;
            text-align: center;
            pointer-events: none;
        }
    }
    &__svg,
    &__close {
        fill: $white;
        display: block;
        margin: 0 auto;
        height: 2rem;
        width: 2rem;
    }
    &__svg {
        display: block;
    }
    &__close {
        display: none;
    }
}

.chatbot-active {
    .chatbot {
        &__block {
            display: block;
            opacity: 1;
        }
        &__icon {
            &-info {
                display: none;
            }
        }
        &__svg {
            display: none;
        }
        &__close {
            display: block;
        }
    }
}

/* 2. Media Screen Style.
   ========================================================================= */

/*media screen for width 991px and down*/
@include media-breakpoint-down(lg) {
    .footer {
        &__list {
            margin-bottom: 1rem;
            text-align: left;
        }
 
        &__link {
            margin: 0 1.5rem 0 0;
        }
    }
}

/*media screen for width 767px and down*/
@include media-breakpoint-down(md) {
    .footer {
        padding: 1.5rem 0;

        &__link {
            margin: 0 1.5rem 0.5rem 0;
        }
    }
    .chatbot {
        &__icon {
            right: 1rem;
            bottom: 1rem;
        }
    }
}

/*media screen for width 575px and down*/
@include media-breakpoint-down(sm) {
    .footer {

        &__item {
            display: block;
            float: left;
            width: 50%;
        }
    }
}