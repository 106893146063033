//
// Base styles
//

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
  height: $card-height;
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  @include border-radius($card-border-radius);
  box-shadow: $box-shadow-angular;
  position: relative;

  &__head {

    display: block;
    margin: 0;
    padding: 0.25rem 0;

    &-item {
      display: inline-block;
      line-height: normal;
      padding: 0 0.5rem;
      position: relative;

      &:before {
        background-color: $gray-200;
        bottom: 0.25rem;
        content: '';
        height: 1rem;
        left: 0;
        top: 0.25rem;
        position: absolute;
        width: 0.0625rem;
      }
    }

    &-user {
      display: inline-block;
      line-height: normal;
      padding: 0 0.5rem 0 2.75rem;
      position: relative;

      &:before {
        display: none;
      }
    }

    &-avatar {

      background-color: $greenDark;
      border-radius: 50%;
      color: $blueDark;
      display: block;
      font-size: 0.875rem;
      height: 2rem;
      line-height: 2rem;
      left: 0;
      text-align: center;
      position: absolute;
      top: -0.25rem;
      width: 2rem;

      img {
          display: block;
          height: 2rem;
          width: 2rem;
      }
    }
  }

  .is-highlight {
    left: -1.0625rem;
    font-size: 0.75rem;
    position: absolute;
    top: 0.625rem;
    text-align: center;
    transform: rotate(-45deg);
    width: 4.5rem;
    white-space: nowrap;
    z-index: 1;
  }

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  > .list-group {
    border-top: inherit;
    border-bottom: inherit;

    &:first-child {
      border-top-width: 0;
      @include border-top-radius($card-inner-border-radius);
    }

    &:last-child  {
      border-bottom-width: 0;
      @include border-bottom-radius($card-inner-border-radius);
    }
  }

  // Due to specificity of the above selector (`.card > .list-group`), we must
  // use a child selector here to prevent double borders.
  > .card-header + .list-group,
  > .list-group + .card-footer {
    border-top: 0;
  }

  &__img {
    overflow: hidden;
    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  &__figure {
    display: block;
    height: 14rem;
    
    img {
      display: block;
      height: 14rem;
      margin: 0 auto;
      max-width: 100%;
      object-fit: cover;
    }
  }

  &__video {
    position: relative;

    .fa-play-circle {
      box-shadow: $box-shadow-button;
      left: 50%;
      position: absolute;
      top: 50%;
      font-size: 5rem;
      opacity: 0.5;
      pointer-events: none;
      margin: -2.5rem 0 0 -2.5rem;
      transition: $transition-base;
    }

    &:hover {
      .fa-play-circle {
        opacity: 0.75;
      }
    }
  }
}

.card-body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  padding: $card-spacer-y $card-spacer-x;
  color: $card-color;
}

.card-title {
  margin-bottom: $card-title-spacer-y;
}

.card-subtitle {
  margin-top: -$card-title-spacer-y / 2;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link {
  &:hover {
    text-decoration: none;
  }

  + .card-link {
    margin-left: $card-spacer-x;
  }
}

//
// Optional textual caps
//

.card-header {
  padding: $card-cap-padding-y $card-cap-padding-x;
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  color: $card-cap-color;
  background-color: $card-cap-bg;
  border-bottom: $card-border-width solid $card-border-color;

  &:first-child {
    @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
  }
}

.card-footer {
  padding: $card-cap-padding-y $card-cap-padding-x;
  color: $card-cap-color;
  background-color: $card-cap-bg;
  border-top: $card-border-width solid $card-border-color;

  &:last-child {
    @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
  }
}


//
// Header navs
//

.card-header-tabs {
  margin-right: -$card-cap-padding-x / 2;
  margin-bottom: -$card-cap-padding-y;
  margin-left: -$card-cap-padding-x / 2;
  border-bottom: 0;

  @if $nav-tabs-link-active-bg != $card-bg {
    .nav-link.active {
      background-color: $card-bg;
      border-bottom-color: $card-bg;
    }
  }
}

.card-header-pills {
  margin-right: -$card-cap-padding-x / 2;
  margin-left: -$card-cap-padding-x / 2;
}

// Card image
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $card-img-overlay-padding;
  @include border-radius($card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
}

.card-img,
.card-img-top {
  @include border-top-radius($card-inner-border-radius);

  
}

.card-img,
.card-img-bottom {
  @include border-bottom-radius($card-inner-border-radius);
}


//
// Card groups
//

.card-group {
  // The child selector allows nested `.card` within `.card-group`
  // to display properly.
  > .card {
    margin-bottom: $card-group-margin;
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-flow: row wrap;
    // The child selector allows nested `.card` within `.card-group`
    // to display properly.
    > .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-bottom: 0;

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {
        &:not(:last-child) {
          @include border-end-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-disallowed-list
            border-top-right-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-disallowed-list
            border-bottom-right-radius: 0;
          }
        }

        &:not(:first-child) {
          @include border-start-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-disallowed-list
            border-top-left-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-disallowed-list
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
}


/* Media Screen Style.
   ========================================================================= */
/*media screen for width 1199px and down*/
@include media-breakpoint-down(xl) {
  .card {

    &__figure {
      height: 12rem;
      
      img {
        height: 12rem;
      }
    }
  }
}


/*media screen for width 991px and down*/
@include media-breakpoint-down(lg) {
  .card {
    &__head {

      &-user {
        padding: 0 0.5rem 0 2.75rem;
        position: relative;
      }
    }

  }
}

/*media screen for width 767px and down*/
@include media-breakpoint-down(md) {
  .card {
    &__head {
  
      &-item {
        display: block;
        padding: 0 0 0 2.75rem;

        &:before {
          display: none;
        }
      }

      &-user {
        padding: 0 0 0 2.75rem;
      }
    }

  }
}

.ribbon {
  line-height: 18px;
  color: $black;
  text-align: center;
  transform: rotate(45deg);
  position: relative;
  font-size: 0.875rem;
  padding: 2px 0;
  left: -4px;
  top: 26px;
  width: 150px;
  background-color: $primary;
  box-shadow: 0 4px 6px rgb(0 0 0 / 10%);
  letter-spacing: 0.5px;
  box-shadow: -3px 5px 6px -5px rgb(0 0 0 / 50%);

  &.is-danger {
    color: $white;
    background-color: $red;
  }

  &__wrap {
    width: 108px;
    height: 108px;
    overflow: hidden;
    position: absolute;
    top: -6px;
    right: -6px;
  }

  &:before {
    content: "";
    position: absolute;
    left: 12px;
    top: 100%;
    z-index: -1;
    border-left: 4px solid rgba(0,0,0,0.5);
    border-right: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-top: 4px solid rgba(0,0,0,0.5);
  }
  &:after {
    content: "";
    position: absolute;
    right: 12px;
    top: 100%;
    z-index: -1;
    border-left: 4px solid transparent;
    border-right: 4px solid rgba(0,0,0,0.5);
    border-bottom: 4px solid transparent;
    border-top: 4px solid rgba(0,0,0,0.5);
  }
  
}
