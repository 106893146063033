@each $color, $value in $theme-colors {
  .link-#{$color},
  .link-#{$color}-reverse {
    color: $value;
    cursor: pointer;
    display: inline-block;
    position: relative;

    &:after {
      content: "";
      background: $value;
      height: 1px;
      bottom: 1px;
      position: absolute;
      transition: $transition-base;
    }
    
    @if $link-shade-percentage != 0 {
      &:hover,
      &:focus {
        color: if(color-contrast($value) == $color-contrast-light, shade-color($value, $link-shade-percentage), tint-color($value, $link-shade-percentage));
      }

      &:after {
        color: if(color-contrast($value) == $color-contrast-light, shade-color($value, $link-shade-percentage), tint-color($value, $link-shade-percentage));
        background: if(color-contrast($value) == $color-contrast-light, shade-color($value, $link-shade-percentage), tint-color($value, $link-shade-percentage));
      }
    }
  }

  .link-#{$color} {
    &:after {
      left: 0;
      right: 0;
    }

    &:hover,
    &:focus {
      &:after {
        left: 50%;
        right: 50%;
      }
    }
  }

  .link-#{$color}-reverse {
    &:after {
      left: 50%;
      right: 50%;
    }

    &:hover,
    &:focus {
      &:after {
        left: 0;
        right: 0;
      }
    }
  }
  
}