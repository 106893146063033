.breadcrumb {
  display: inline;
  padding: 0;
  @include font-size($breadcrumb-font-size);
  list-style: none;
  background-color: $breadcrumb-bg;
  @include border-radius($breadcrumb-border-radius);

  &__highlight {
    display: block;
    padding: 0.5rem 1rem;
    line-height: 1.3;
    position: relative;

    &:before {
      background: $gray-border;
      bottom: 0;
      content: '';
      width: 0.0625rem;
      top: 0;
      position: absolute;
      left: 0;
    }
  }

  &__filter {
    border-left: 0.0625rem solid $gray-border;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.75rem;
    bottom: 0;
  }
}

.breadcrumb-item {
  display: inline-block;
  // The separator between breadcrumbs (by default, a forward-slash: "/")
    padding-left: $breadcrumb-item-padding-x;

    &::before {
      float: left; // Suppress inline spacings and underlining of the separator
      padding-right: $breadcrumb-item-padding-x;
      color: $breadcrumb-divider-color;
      content: var(--#{$variable-prefix}breadcrumb-divider, escape-svg($breadcrumb-divider)) #{"/* rtl:"} var(--#{$variable-prefix}breadcrumb-divider, escape-svg($breadcrumb-divider-flipped)) #{"*/"};
      opacity: 0.5;
    }
    &:first-child {
      &::before {
        display: none;
      }
    }

  a {
    color: $gray-600;

    &:hover,
    &:focus {
      color: $gray-600;
      opacity: 0.75;
    }
  }

  &.active {
    color: $breadcrumb-active-color;
  }
}
/*media screen for width 767px and up*/
@include media-breakpoint-down(up) {
  .breadcrumb {
    margin: $breadcrumb-margin;
  }
}
/*media screen for width 767px and down*/
@include media-breakpoint-down(md) {

  .breadcrumb {
    margin: 0.5rem 0;
  
    &__highlight {

      &-wrap{
        background-color: $white;
        border-bottom: 0.0625rem solid $gray-border;
        margin: 0.5rem 0 0 0;
      }
      &:first-child {
        &:before {
          display: none;
        }
      }
    }
  }
}