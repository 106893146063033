//
// Check/radio
//

.form-check {
  display: block;
  min-height: $form-check-min-height;
  padding-left: $form-check-padding-start;
  margin-bottom: $form-check-margin-bottom;

  .form-check-input {
    float: left;
    margin-left: $form-check-padding-start * -1;
  }
}

.form-check-input {
  width: $form-check-input-width;
  height: $form-check-input-width;
  margin-top: 0; // line-height minus check height
  vertical-align: top;
  background-color: $form-check-input-bg;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: $form-check-input-border;
  appearance: none;
  color-adjust: exact; // Keep themed appearance for print
  @include transition($form-check-transition);

  &[type="checkbox"] {
    @include border-radius($form-check-input-border-radius);
  }

  &[type="radio"] {
    // stylelint-disable-next-line property-disallowed-list
    border-radius: $form-check-radio-border-radius;
  }

  &:active {
    filter: $form-check-input-active-filter;
  }

  &:focus {
    border-color: $form-check-input-focus-border;
    outline: 0;
    box-shadow: $form-check-input-focus-box-shadow;
  }

  &:checked {
    background-color: $form-check-input-checked-bg-color;
    border-color: $form-check-input-checked-border-color;

    &[type="checkbox"] {
      @if $enable-gradients {
        background-image: escape-svg($form-check-input-checked-bg-image), var(--#{$variable-prefix}gradient);
      } @else {
        background-image: escape-svg($form-check-input-checked-bg-image);
      }
    }

    &[type="radio"] {
      @if $enable-gradients {
        background-image: escape-svg($form-check-radio-checked-bg-image), var(--#{$variable-prefix}gradient);
      } @else {
        background-image: escape-svg($form-check-radio-checked-bg-image);
      }
    }
  }

  &[type="checkbox"]:indeterminate {
    background-color: $form-check-input-indeterminate-bg-color;
    border-color: $form-check-input-indeterminate-border-color;

    @if $enable-gradients {
      background-image: escape-svg($form-check-input-indeterminate-bg-image), var(--#{$variable-prefix}gradient);
    } @else {
      background-image: escape-svg($form-check-input-indeterminate-bg-image);
    }
  }

  &:disabled {
    pointer-events: none;
    filter: none;
    opacity: $form-check-input-disabled-opacity;
  }

  // Use disabled attribute in addition of :disabled pseudo-class
  // See: https://github.com/twbs/bootstrap/issues/28247
  &[disabled],
  &:disabled {
    ~ .form-check-label {
      opacity: $form-check-label-disabled-opacity;
    }
  }
}

.form-check-label {
  color: $form-check-label-color;
  cursor: $form-check-label-cursor;
}

//
// Switch
//

.form-switch {
  padding-left: $form-switch-padding-start;

  .form-check-input {
    width: $form-switch-width;
    margin-left: $form-switch-padding-start * -1;
    background-image: escape-svg($form-switch-bg-image);
    background-position: left center;
    @include border-radius($form-switch-border-radius);
    @include transition($form-switch-transition);

    &:focus {
      background-image: escape-svg($form-switch-focus-bg-image);
    }

    &:checked {
      background-position: $form-switch-checked-bg-position;

      @if $enable-gradients {
        background-image: escape-svg($form-switch-checked-bg-image), var(--#{$variable-prefix}gradient);
      } @else {
        background-image: escape-svg($form-switch-checked-bg-image);
      }
    }
  }
}

.form-check-inline {
  display: inline-block;
  margin-right: $form-check-inline-margin-end;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;

  &[disabled],
  &:disabled {
    + .btn {
      pointer-events: none;
      filter: none;
      opacity: $form-check-btn-check-disabled-opacity;
    }
  }
}

.switch-button {
  border-radius: 1.5rem;
  border: 1px solid #cccfd3;
  overflow: hidden;
  width: 100%;
  text-align: center;
  font-size: 0.875rem;
  letter-spacing: 1px;
  color: #155FFF;
  position: relative;
  padding-right: 50%;
 

  &:before {
    content: "Care Member";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
  }

  &-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;

    &:checked + .switch-button-label:before {
      transform: translateX(120px);
      transition: transform 300ms linear;
    }

    & + .switch-button-label {
      position: relative;
      padding: 0.25rem 0;
      display: block;
      user-select: none;
      pointer-events: none;

      &:before {
        content: "";
        background: $secondary;
        border: 2px solid #fff;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 1.5rem;
        transform: translateX(0);
        transition: transform 300ms;
      }

      .switch-button-label-span {
        position: relative;
      }
    }
  }
}
