// scss-docs-start caret-mixins
@mixin caret-down {
  content: "\f078";
}

@mixin caret-up {
  content: "\f077";
}

@mixin caret-end {
  content: "\f054";
}

@mixin caret-start {
  content: "\f053";
}

@mixin caret($direction: down) {
  @if $enable-caret {
    &::after {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      font-size: 75%;
      margin-left: 0.25rem;
      content: "";
      transition: $transition-base;
      @if $direction == down {
        @include caret-down();
      } @else if $direction == up {
        @include caret-up();
      } @else if $direction == end {
        @include caret-end();
      }
    }

    @if $direction == start {
      &::after {
        //display: none;
      }

      &::before {
        font-weight: 900;
        font-family: "Font Awesome 5 Free";
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        content: "";
        transition: $transition-base;
        @include caret-start();
      }
    }

    &:empty::after {
      margin-left: 0;
    }
  }
}
// scss-docs-end caret-mixins
